  <template>
  <div class="base-table">
    <div class="header">
      <div class="title">
        {{ title }}
      </div>
      <div class="btn">
        <slot name="headerBtn"></slot>
      </div>
    </div>
    <el-table :data="tableData" stripe border>
      <el-table-column
        v-if="showSelectColumn"
        type="selection"
        align="center"
        width="60"
      ></el-table-column>
      <el-table-column
        v-if="showIndexColumn"
        type="index"
        label="序号"
        align="center"
        width="80"
      ></el-table-column>
      <template v-for="(propItem, pIndex) in propsList">
        <el-table-column
          v-bind="propItem"
          show-overflow-tooltip
          align="center"
          :key="pIndex"
        >
          <template #default="scope">
            <slot :name="propItem.slotName" :row="scope.row">
              {{ scope.row[propItem.prop] }}
            </slot>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div class="footer" v-if="showFooter">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :currentPage="searchInfo.currentPage"
        :page-size="searchInfo.size"
        :page-sizes="[10, 20, 30]"
        :total="dataCount"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseTable",
  props: {
    title: {
      type: String,
      default: "",
    },
    propsList: {
      type: Array,
      require: true,
    },
    showIndexColumn: {
      type: Boolean,
      default: true,
    },
    showSelectColumn: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    tableData: {
      type: Array,
      require: true,
    },
    dataCount: {
      type: Number,
    },
  },
  data() {
    return {
      searchInfo: {
        currentPage: 0,
        size: 5,
      },
    };
  },
  methods: {
    handleSizeChange(size) {
      this.$emit("getTableData", { ...this.searchInfo, size });
    },
    handleCurrentChange(currentPage) {
      this.$emit("getTableData", { ...this.searchInfo, currentPage });
    },
  },
};
</script>
<style lang="scss" scoped>
.base-table {
  // padding: 10px;
  // border-top: 20px solid #f0f2f5;
  margin-top: 20px;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .footer {
    margin-top: 15px;

    .el-pagination {
      margin-right: -6px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
