import {
	request
} from "./request.js"

// 获取溯源数据
function getHistory(option) {
	return request({
		url: '/synergy/trace/code/findByGasCode',
		method: 'POST',
		data: option,
		err:(res) => {
			console.log(res)
			setTimeout(() => {
				uni.navigateBack({
					delta: 1
				});
			}, 1000)
		},
	})
}

// 下载图片
function dowmFile(option) {
	return request({
		url: '/synergy/uploadFile/downloadByUrl?name='+option.name+'&url=' + option.url ,
		method: 'POST',
	})
}


export {
	getHistory,
	dowmFile
}
