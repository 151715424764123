const ropSearchFormConfig = {
  // labelWidth: "150px",
  formItems: [
    {
      field: "customerName",
      type: "input",
      label: "客户名称",
      placeholder: "请输入客户名称",
    },
    {
      field: "customerPhone",
      type: "input",
      label: "客户手机",
      placeholder: "请输入客户手机",
    },
    {
      field: "customerDddress",
      type: "input",
      label: "客户地址",
      placeholder: "请输入客户地址",
    },
    {
      field: "exchangeStatus",
      type: "select",
      label: "兑换状态",
      placeholder: "请选择兑换状态",
      options: [
        { title: "未兑换", value: 0 },
        { title: "已兑换", value: 1 },
      ],
    },
  ],
};
const ropTableConfig = {
  title: "积分兑换",
  propsList: [
    { prop: "customerName", label: "客户名称", minWidth: "80" },
    { prop: "customerPhone", label: "客户手机", minWidth: "80" },
    { prop: "customerDddress", label: "客户地址", minWidth: "80" },
    { prop: "integralRecord", label: "兑换记录", minWidth: "80" },
    { prop: "integral", label: "积分", minWidth: "80" },
    {
      prop: "operation",
      label: "操作",
      minWidth: "120",
      slotName: "handler",
    },
  ],
  showSelectColumn: false,
  showIndexColumn: true,
};
const ropEditFormConfig = {
  formItems: [
    {
      field: "productNumber",
      type: "select",
      label: "兑换商品",
      placeholder: "请选择商品",
      options: [
        { title: "莫代尔内裤", value: "816" },
        { title: "无痕内衣", value: "B78" },
      ],
    },
  ],
  rules: {
    productNumber: [
      { required: true, message: "请选择商品", trigger: "change" },
    ],
  },
};

export { ropSearchFormConfig, ropTableConfig, ropEditFormConfig };
