<template>
  <div class="">
    <SearchForm
      v-bind="ropSearchFormConfig"
      @handleResetClick="handleResetClick"
      @handleSearchClick="handleSearchClick"
    >
    </SearchForm>
    <MyTable
      :tableData="tableData"
      :tableConfig="ropTableConfig"
      @getTableData="getTableData"
    >
      <template #handler="scope">
        <div class="handle-btns">
          <el-button
            size="small"
            type="text"
            @click="handleExchangeClick(scope.row)"
            >兑换
          </el-button>
        </div>
      </template>
    </MyTable>
    <el-dialog
      title="兑换商品"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <RuleForm v-bind="ropEditFormConfig" />
    </el-dialog>
  </div>
</template>

<script>
import MyTable from "@/components/MyTable.vue";
import SearchForm from "@/components/SearchForm.vue";
import RuleForm from "@/components/RuleForm.vue";

import {
  ropSearchFormConfig,
  ropTableConfig,
  ropEditFormConfig,
} from "./config";
import tableMixins from "@/mixins/tablePublicMethods";

export default {
  name: "RedemptionOfPoints",
  components: {
    MyTable,
    SearchForm,
    RuleForm,
  },
  mixins: [tableMixins],
  data() {
    return {
      ropSearchFormConfig,
      ropTableConfig,
      tableData: [
        {
          customerName: "王小姐",
          customerPhone: "13530838077",
          customerDddress: "公园里二期3A2807",
          integralRecord: "莫代尔内裤",
          integral: "80",
        },
      ],
      ropEditFormConfig,
      centerDialogVisible: false,
      editFormData: {},
    };
  },
  methods: {
    handleExchangeClick() {
      console.log("兑换");
      this.centerDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
