import {
	request
} from "./request.js"

// //获取供应商申请列表数据
// function getMineInfo() {
// 	return request({
// 		url: '/eidpws/vip/salesChannel/category'
// 	})
// }

//修改个人信息
function updateInfo(option={}) {
	return request({
		url: '/synergy/user/updateInfo',
		method: 'POST',
		data:option
	})
}

//获取违约记录
function getRecord(option={}) {
	return request({
		url: '/synergy/reservationOrder/findBreachListByNormal',
		method: 'POST',
		data:option
	})
}

//退出登录
function loginOut(){
	return request({
		url:'/synergy/logout'
	})
}


export {
	// getMineInfo,
	loginOut,
	getRecord,
	updateInfo
}
