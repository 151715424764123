import {
	request
} from "./request.js"

//获取供应商申请列表数据
function getApplyList(orderNo) {
	return request({
		url: '/synergy/reservationOrder/' + orderNo + '/findOrder'
	})
}

//获取预约类型详情
function reservationTypeInfo(reservationTypeId){
	return request({
		url:'/synergy/reservationOrder/'+reservationTypeId+'/reservationTypeInfo'
	})
}

//获取预约时间
function getApplyTime(reservationType){
	return request({
		url:'/synergy/reservationOrder/'+reservationType+'/findReservationDate'
	})
}

// 预约申请 
function submitApply(option){
	return request({
		url:'/synergy/reservationOrder/saveOrder',
		method:'POST',
		data:option
	})
}


export {
	getApplyList,
	getApplyTime,
	submitApply,
	reservationTypeInfo
}
