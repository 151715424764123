import {
	request
} from './request.js'

//获取预约列表数据
function getList(option={}) {
	return request({
		url: '/synergy/reservationOrder/findListByNormal',
		method: 'POST',
		data:option
	})
}

//查看 预约列表/违约记录 详情
function getOrderDetailList(reservationId) {
	return request({
		url: '/synergy/reservationOrder/' + reservationId + '/fingById'
	})
}

// 取消预约
function cancelOrder(reservationId) {
	return request({
		url: '/synergy/reservationOrder/' + reservationId + '/cancelOrder',
	})
}

export {
	getList,
	getOrderDetailList,
	cancelOrder
}
