const searchFormConfig = {
  // labelWidth: "150px",
  formItems: [
    {
      field: "productName",
      type: "input",
      label: "商品名称",
      placeholder: "请输入商品名称",
    },
    {
      field: "productNumber",
      type: "input",
      label: "商品编号",
      placeholder: "请输入商品编号",
    },
    {
      field: "productCategory",
      type: "select",
      label: "商品品类",
      placeholder: "请选择商品品类",
      options: [
        { title: "文胸", value: 1 },
        { title: "内裤", value: 0 },
      ],
    },
  ],
};
const tableConfig = {
  title: "商品列表",
  propsList: [
    { prop: "productNumber", label: "商品编号", slotName: "productNumber" },
    { prop: "productName", label: "商品名称", slotName: "productName" },
    { prop: "colorSku", label: "颜色", minWidth: "150", slotName: "colorSku" },
    { prop: "sizeSku", label: "码数", minWidth: "150", slotName: "sizeSku" },
    { prop: "price", label: "价格", minWidth: "80", slotName: "price" },
    { prop: "imgUrl", label: "商品图片", minWidth: "100", slotName: "imgUrl" },
    {
      prop: "salesVolume",
      label: "销量",
      minWidth: "80",
      slotName: "salesVolume",
    },
    {
      prop: "operation",
      label: "操作",
      minWidth: "120",
      slotName: "handler",
    },
  ],
  showSelectColumn: false,
  showIndexColumn: true,
};
const addFormConfig = {
  formItems: [
    {
      field: "productCategory",
      type: "select",
      label: "商品品类",
      placeholder: "请选择商品品类",
      options: [
        { title: "文胸", value: 0 },
        { title: "内裤", value: 1 },
      ],
    },
    {
      field: "productNumber",
      type: "input",
      label: "商品编号",
      placeholder: "请输入商品编号",
    },
    {
      field: "productName",
      type: "input",
      label: "商品名称",
      placeholder: "请输入商品名称",
    },
    {
      field: "colorSku",
      type: "input",
      label: "商品颜色",
      placeholder: "请输入商品颜色",
    },
    {
      field: "sizeSku",
      type: "input",
      label: "商品码数",
      placeholder: "请输入商品码数",
    },
    {
      field: "price",
      type: "input",
      label: "商品价格",
      placeholder: "请输入商品价格",
    },
    {
      field: "imgUrl",
      type: "upload",
      label: "商品图片",
      placeholder: "请上传图片",
    },
  ],
  rules: {
    productCategory: [
      { required: true, message: "请选择品类", trigger: "change" },
    ],
    productNumber: [{ required: true, message: "请输入编号", trigger: "blur" }],
    productName: [
      { required: true, message: "请输入活动名称", trigger: "blur" },
    ],
    colorSku: [{ required: true, message: "请输入商品颜色", trigger: "blur" }],
    sizeSku: [{ required: true, message: "请输入商品码数", trigger: "blur" }],
    price: [{ required: true, message: "请输入价格", trigger: "blur" }],

    // type: 'array',
    imgUrl: [{ required: true, message: "请上传图片", trigger: "change" }],
  },
};
export { searchFormConfig, tableConfig, addFormConfig };
