import {
	request
} from "./request.js"

//获取openId
function getOpenId(option) {
	return request({
		url: '/synergy/getOpenId/' + option.jsCode
	})
}

// 绑定微信
function bindWx(option) {
	return request({
		url: '/synergy/login',
		method: 'POST',
		data: option
	})
}

//已经绑定过了,直接登录
function directLogin(option) {
	return request({
		url: '/synergy/login',
		method: 'POST',
		data: option
	})
}

//获取验证码
function getCode(option) {
	return request({
		// url: '/synergy/register/' + option.email + '/sendEmail',
        url:'/synergy/register/' + option.phone + '/sendVerifyCode?type=' + option.type
	})
}
//注册
function register(option) {
	return request({
		url: '/synergy/register/vendor/register',
		method: 'post',
		data: option
	})
}

// 找回密码
function takePassword(option) {
	return request({
		url: '/synergy/register/vendor/forget',
		method: 'post',
		data: option
	})
}


export {
	getOpenId,
	bindWx,
	directLogin,
	register,
	getCode,
	takePassword
}
