<template>
  <div class="">
    <SearchForm
      v-bind="searchFormConfig"
      @handleResetClick="handleResetClick"
      @handleSearchClick="handleSearchClick"
    >
    </SearchForm>
    <MyTable
      :tableData="tableData"
      :tableConfig="tableConfig"
      @getTableData="getTableData"
    >
      <template #deliveryStatus="scope">
        <el-tag
          :type="
            scope.row.deliveryStatus == '0'
              ? 'info'
              : scope.row.deliveryStatus == '1'
              ? 'warning'
              : 'success'
          "
          effect="dark"
        >
          {{
            scope.row.deliveryStatus == "0"
              ? "待配送"
              : scope.row.deliveryStatus == "1"
              ? "配送中"
              : "已配送"
          }}</el-tag
        >
      </template>
      <template #handler="scope">
        <div class="handle-btns">
          <el-button
            v-if="scope.row.deliveryStatus == '0'"
            size="small"
            type="text"
            @click="handleStartDeliverClick(scope.row)"
            >开始配送
          </el-button>
          <el-button
            v-if="scope.row.deliveryStatus == '1'"
            size="small"
            type="text"
            @click="handleDeliveredClick(scope.row)"
            >已送达
          </el-button>
        </div>
      </template>
    </MyTable>
  </div>
</template>

<script>
import MyTable from "@/components/MyTable.vue";
import SearchForm from "@/components/SearchForm.vue";

import { tableConfig, searchFormConfig } from "./config";
import tableMixins from "@/mixins/tablePublicMethods";

export default {
  name: "DeliveryManagement",
  components: {
    MyTable,
    SearchForm,
  },
  mixins: [tableMixins],
  data() {
    return {
      tableConfig,
      searchFormConfig,
      tableData: [
        {
          customerName: "王小姐",
          customerPhone: "13530838077",
          customerDddress: "公园里二期3A2807",
          productNumber: "B78",
          selectSku: "黑色/M",
          deliveryStatus: "1",
        },
      ],
    };
  },
  methods: {
    handleStartDeliverClick() {
      console.log("开始配送");
    },
    handleDeliveredClick() {
      console.log("配送完成");
    },
  },
};
</script>

<style lang="scss" scoped></style>
