const baseURL = "http://192.168.1.69:8003"; //测试环境
// const baseURL = 'https://iportal.szgas.com.cn'; //正式环境

// 封装网络请求
function request(option) {
  return new Promise(function (resolve, reject) {
    var _self = this,
      url = baseURL + option.url,
      method = option.method || "GET",
      data = option.data || {},
      header = option.header || {
        "content-type": "application/json",
      },
      token = uni.getStorageSync("token") || "";

    // 登录不需要cookies
    if (
      option.url !== "/synergy/login" &&
      option.url !== "/synergy/getOpenId"
    ) {
      header["Cookie"] = uni.getStorageSync("Cookie");
    }
    // header['Accept'] = 'application/json'

    //GET或POST
    if (method) {
      method = method.toUpperCase(); //小写改为大写
    }

    // console.log('时间戳', timestamp)
    console.log("请求地址", url);
    // console.log('请求头', header)
    // console.log('请求方法', method)
    console.log("请求参数", data);

    uni.showLoading({
      title: "加载中",
      mask: true,
    });

    //网络请求
    uni.request({
      url: url,
      method: method,
      header: header,
      data: data,

      //请求成功
      success: (res) => {
        console.log("返回数据", res);
        uni.hideLoading();

        //登录过期
        if (res.data.status == "403") {
          // token过期 返回登录页
          uni.clearStorageSync();
          uni.showModal({
            showCancel: false,
            title: "授权信息过期,请重新登录",
            success() {
              uni.clearStorageSync();
              uni.reLaunch({
                url: "/pages/login/login",
              });
            },
          });
          reject(res.data.msg);
        } else if (res.data.status == true) {
          resolve(res);
        } else {
          if (option.err) {
            option.err(res);
          }

          //当前微信未绑定账号
          if (res.data.code == 401) {
            resolve(res);
          } else {
            uni.showToast({
              title: res.data.message,
              icon: "none",
              duration: 2000,
            });
            reject(res);
          }
        }
      },

      //请求失败
      fail: (err) => {
        uni.hideLoading();
        uni.showModal({
          showCancel: false,
          title: "请求接口失败,请联系管理员",
          success() {
            // uni.clearStorageSync()
            uni.navigateTo({
              url: "/pages/mine/mine	",
            });
          },
        });
        console.log("请求失败的err", err);
        reject(err);
      },
    });
  });
}

module.exports = {
  request,
};
