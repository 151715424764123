//批量导出请求文件,无需逐个页面引入

const requireApi = require.context(
	//api相对路径
	'.',
	//是否查询子目录
	false,
	//查询文件的一个后缀
	/.js$/
)
let myModule = {}
requireApi.keys().forEach((key, index) => {
	if (key === './index.js' || key === "./request.js") return
	Object.assign(myModule,requireApi(key))
})

export default myModule
