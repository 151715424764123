<template>
  <el-menu
    class="el-menu-vertical-demo menu"
    :default-active="onRoutes"
    @open="handleOpen"
    @close="handleClose"
    collapse-transition
    :default-openeds="['1', '2', '3']"
    router
  >
    <el-submenu
      v-for="(mItem, mIndex) in menuList"
      :index="mItem.id"
      :key="mItem.id"
    >
      <template slot="title">
        <i class="el-icon-location"></i>
        <span>{{ mItem.groupName }}</span>
      </template>
      <el-menu-item
        :index="'/' + sItem.routePath"
        :key="sItem.id"
        v-for="(sItem, sIndex) in mItem.sonList"
        >{{ sItem.itemName }}</el-menu-item
      >
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  name: "Menu",
  components: {},
  data() {
    return {
      menuList: [
        {
          groupName: "商品管理",
          id: "1",
          sonList: [
            {
              itemName: "商品列表",
              id: "1-1",
              routePath: "productManagement/productList",
            },
          ],
        },
        {
          groupName: "配送管理",
          id: "2",
          sonList: [
            {
              itemName: "配送列表",
              id: "2-1",
              routePath: "deliveryManagement/deliveryList",
            },
          ],
        },
        {
          groupName: "用户管理",
          id: "3",
          sonList: [
            {
              itemName: "积分兑换",
              id: "3-1",
              routePath: "userManagement/redemptionOfPoints",
            },
          ],
        },
      ],
    };
  },
  computed: {
    onRoutes() {
      // console.log(this.$route.path);
      return this.$route.path;
    },
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    selectClick(index, path) {
      console.log(index, path);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
}
</style>
