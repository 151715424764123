<template>
  <div class="">
    <el-form
      :model="ruleFormData"
      :rules="rules"
      ref="ruleForm"
      :label-width="labelWidth"
      label-width="100px"
      class="demo-ruleForm"
    >
      <template v-for="(fItem, fIndex) in formItems">
        <el-form-item
          :label="fItem.label"
          :style="itemStyle"
          :prop="fItem.field"
          :key="fIndex"
        >
          <template v-if="fItem.type === 'input'">
            <el-input
              v-model="ruleFormData[`${fItem.field}`]"
              v-bind="fItem.otherOptions"
              :placeholder="fItem.placeholder"
            />
          </template>
          <template v-else-if="fItem.type === 'select'">
            <el-select
              style="width: 100%"
              v-model="ruleFormData[`${fItem.field}`]"
              v-bind="fItem.otherOptions"
              :placeholder="fItem.placeholder"
            >
              <el-option
                v-for="oItem in fItem.options"
                :label="oItem.title"
                :value="oItem.value"
                :key="oItem.value"
              />
            </el-select>
          </template>
          <template v-else-if="fItem.type === 'upload'">
            <el-upload
              action="#"
              list-type="picture-card"
              :http-request="(e) => handleFileUpload(e, fItem.field)"
              :on-preview="handlePictureCardPreview"
              :on-remove="(e) => handleRemove(e, fItem.field)"
              :before-upload="handleBeforeUpload"
              :file-list="ruleFormData[`${fItem.field}`]"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </template>
        </el-form-item>
      </template>
      <el-form-item class="footer-box">
        <el-button @click="resetForm('ruleForm')">重置</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "RuleForm",
  props: {
    visibleType: {
      type: String,
      default: "",
    },
    formItems: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Object,
      default: () => {},
    },
    editFormData: {
      type: Object,
      default: () => {},
    },
    labelWidth: {
      type: String,
      default: "100px",
    },
    itemStyle: {
      type: Object,
      default: () => {
        padding: "10px 40px";
      },
    },
  },
  watch: {
    visibleType: {
      handler(newVal) {
        if (newVal == "edit") {
          //编辑
          this.formItems.forEach((fItem) => {
            this.$set(
              this.ruleFormData,
              fItem.field,
              this.editFormData[fItem.field]
            );
          });
        } else {
          //新增
          this.formItems.forEach((fItem) => {
            if (fItem.type == "upload") {
              this.$set(this.ruleFormData, fItem.field, []);
            } else {
              this.$set(this.ruleFormData, fItem.field, "");
            }
          });
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      ruleFormData: {},
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  methods: {
    handleFileUpload(fileObject, field) {
      console.log("文件信息", fileObject, this.ruleFormData);
      this.ruleFormData[`${field}`].push(fileObject.file);
    },
    // 移除
    handleRemove(file, field) {
      let removeArr = this.ruleFormData[`${field}`];
      if (removeArr.length > 1) {
        this.ruleFormData[`${field}`] = removeArr.filter(
          (fItem) => fItem.name == file.name
        );
      } else {
        this.ruleFormData[`${field}`] = [];
      }

      // this.$emit('placardUpload', fileList.join(''))
    },
    // 查看大图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 检查图片
    handleBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.warning("上传图片不能大于2M");
      }
      return true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("提交表单", this.ruleFormData);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.footer-box {
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/ .el-form-item__content {
    margin-left: 0px !important;
  }
  margin-bottom: 0px;
}
</style>
