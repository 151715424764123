<template>
  <div class="">
    <SearchForm
      v-bind="searchFormConfig"
      @handleResetClick="handleResetClick"
      @handleSearchClick="handleSearchClick"
    >
    </SearchForm>
    <MyTable
      :tableData="tableData"
      :tableConfig="tableConfig"
      pageName="goods"
      @getTableData="getTableData"
    >
      <template #headerBtn>
        <el-button type="primary" @click="handleAddClick"> 新建商品 </el-button>
      </template>

      <template #imgUrl="scope">
        <el-image
          style="width: 60px; height: 60px"
          preview-teleported
          :z-index="999"
          :src="scope.row.imgUrl"
          :preview-src-list="[scope.row.imgUrl]"
          fit="cover"
        />
      </template>
      <template #colorSku="scope">
        <el-tag
          v-for="sItem in scope.row.colorSku.split(',')"
          :key="sItem"
          effect="dark"
          style="margin-left: 5px"
        >
          {{ sItem }}
        </el-tag>
      </template>
      <template #sizeSku="scope">
        <el-tag
          v-for="sItem in scope.row.sizeSku.split(',')"
          :key="sItem"
          effect="dark"
          style="margin-left: 5px"
        >
          {{ sItem }}
        </el-tag>
      </template>
      <template #price="scope">
        {{ "￥" + scope.row.price }}
      </template>
      <template #handler="scope">
        <div class="handle-btns">
          <el-button
            size="small"
            type="text"
            @click="handleEditClick(scope.row)"
            >编辑
          </el-button>
          <el-button type="text" @click="handleDeteleClick(scope.row)"
            >删除</el-button
          >
        </div>
      </template>
    </MyTable>
    <el-dialog
      :title="visibleType == 'add' ? '新建商品' : '编辑商品'"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <RuleForm
        v-bind="addFormConfig"
        :visibleType="visibleType"
        :editFormData="editFormData"
      />
    </el-dialog>
  </div>
</template>

<script>
import MyTable from "@/components/MyTable.vue";
import SearchForm from "@/components/SearchForm.vue";
import RuleForm from "@/components/RuleForm.vue";

import { tableConfig, searchFormConfig, addFormConfig } from "./config";
import tableMixins from "@/mixins/tablePublicMethods";

export default {
  name: "ProductList",
  components: {
    MyTable,
    SearchForm,
    RuleForm,
  },
  mixins: [tableMixins],
  data() {
    return {
      tableConfig,
      searchFormConfig,
      tableData: [
        {
          productNumber: "B78",
          productName: "无痕内衣",
          colorSku: "香芋紫,优雅黑",
          sizeSku: "S,M,L,XL",
          price: "27",
          imgUrl:
            "https://uxresources.baozun.com/prod/88000028/20240416/925B7D1E0E77042D66E6C77AF25F9514.jpg?x-oss-process=image/interlace,1/resize,m_pad,w_370,h_490",
          salesVolume: 10,
        },
        {
          productNumber: "B78",
          productName: "无痕内衣",
          colorSku: "香芋紫,优雅黑",
          sizeSku: "S,M,L,XL",
          price: "27",
          imgUrl:
            "https://uxresources.baozun.com/prod/88000028/20240416/925B7D1E0E77042D66E6C77AF25F9514.jpg?x-oss-process=image/interlace,1/resize,m_pad,w_370,h_490",
          salesVolume: 10,
        },
        {
          productNumber: "B78",
          productName: "无痕内衣",
          colorSku: "香芋紫,优雅黑",
          sizeSku: "S,M,L,XL",
          price: "27",
          imgUrl:
            "https://uxresources.baozun.com/prod/88000028/20240416/925B7D1E0E77042D66E6C77AF25F9514.jpg?x-oss-process=image/interlace,1/resize,m_pad,w_370,h_490",
          salesVolume: 10,
        },
      ],
      visibleType: "add",
      addFormConfig,
      centerDialogVisible: false,
      editFormData: {},
    };
  },
  methods: {
    handleAddClick() {
      this.visibleType = "add";
      this.centerDialogVisible = true;
    },
    handleEditClick() {
      this.visibleType = "edit";
      this.editFormData = {
        productCategory: 1,
        productNumber: "B78",
        productName: "无痕内衣",
        colorSku: "香芋紫,优雅黑",
        sizeSku: "S,M,L,XL",
        price: "27",
        imgUrl:
          "https://uxresources.baozun.com/prod/88000028/20240416/925B7D1E0E77042D66E6C77AF25F9514.jpg?x-oss-process=image/interlace,1/resize,m_pad,w_370,h_490",
      };
      this.centerDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
