<template>
  <div class="home">
    <el-container>
      <el-header >彩姿内衣袜业批发</el-header>
      <el-container>
        <el-aside width="200px">
          <Menu />
        </el-aside>
        <el-main><Main /></el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "./menu/index.vue";
import Main from "./main/index.vue";

export default {
  name: "Home",
  components: {
    Menu,
    Main,
  },
};
</script>
<style lang="scss" scoped>
.home {
  color: #333;
}
.el-header {
  background-color: #6978ff;
  color: #fff;
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-aside {
  height: 100vh;
  background-color: #d3dce6;
}

.el-main {
  height: 100vh;
  background-color: #e9eef3;
}

body > .el-container {
  margin-bottom: 40px;
}
</style>
