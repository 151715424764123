const searchFormConfig = {
  // labelWidth: "150px",
  formItems: [
    {
      field: "customerName",
      type: "input",
      label: "客户名称",
      placeholder: "请输入客户名称",
    },
    {
      field: "customerPhone",
      type: "input",
      label: "客户手机",
      placeholder: "请输入客户手机",
    },
    {
      field: "customerDddress",
      type: "input",
      label: "客户地址",
      placeholder: "请输入客户地址",
    },
    {
      field: "deliveryStatus",
      type: "select",
      label: "配送状态",
      placeholder: "请选择配送状态",
      options: [
        { title: "待配送", value: 0 },
        { title: "配送中", value: 1 },
        { title: "已配送", value: 2 },
      ],
    },
  ],
};
const tableConfig = {
  title: "配送列表",
  propsList: [
    {
      prop: "customerName",
      label: "客户名称",
      minWidth: "80",
      slotName: "customerName",
    },
    {
      prop: "customerPhone",
      label: "客户手机",
      minWidth: "80",
      slotName: "customerPhone",
    },
    {
      prop: "customerDddress",
      label: "客户地址",
      minWidth: "80",
      slotName: "customerDddress",
    },
    {
      prop: "productNumber",
      label: "商品编号",
      minWidth: "80",
      slotName: "productNumber",
    },
    {
      prop: "selectSku",
      label: "商品规格",
      minWidth: "80",
      slotName: "selectSku",
    },
    {
      prop: "deliveryStatus",
      label: "配送状态",
      minWidth: "80",
      slotName: "deliveryStatus",
    },
    {
      prop: "operation",
      label: "操作",
      minWidth: "120",
      slotName: "handler",
    },
  ],
  showSelectColumn: false,
  showIndexColumn: true,
};
export { searchFormConfig, tableConfig };
