<template>
  <div class="my-table">
    <BaseTable
      :tableData="tableData"
      v-bind="tableConfig"
      :dataCount="dataCount"
      @getTableData="getTableData"
    >
      <!-- 1.头部插槽 -->
        <template #headerBtn>
        <slot name="headerBtn"></slot>
      </template>

      <!-- 2.内容区，公共插槽 -->
      <!-- 日期用统一方法处理 -->
      <template #createAt="scope">
        <span>
          {{ scope.row.createAt }}
        </span>
      </template>
      <template #updateAt="scope">
        <span>
          {{ scope.row.updateAt }}
        </span>
      </template>

      <!-- 2.内容区，私有插槽 -->
      <template v-for="pItem in otherPropsList" #[pItem.slotName]="scope">
        <slot :name="pItem.slotName" :row="scope.row"></slot>
      </template>
    </BaseTable>
  </div>
</template>
<script>
import BaseTable from "./BaseTable.vue";
export default {
  name: "MyTable",
  components: {
    BaseTable,
  },
  props: {
    tableConfig: {
      type: Object,
      require: true,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      otherPropsList: [],
      dataCount: 20,
    
    };
  },
  created() {
    // 获取私有插槽
    this.otherPropsList = this.tableConfig?.propsList.filter((pItem) => {
      switch (pItem.slotName) {
        case "createAt":
        case "createAt":
        case "updateAt":
        case undefined:
          return false;
        default:
          return true;
      }
    });
  },
  methods: {
    getTableData(searchInfo) {
      console.log("改变页码1", searchInfo);
      this.$emit("getTableData", searchInfo);
    },
  },
};
</script>
<style></style>
