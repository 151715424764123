<template>
  <div class="base-form-box">
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="main-form">
      <el-form
        :model="formData"
        status-icon
        :label-width="labelWidth"
        class="demo-formData"
      >
        <el-row>
          <template v-for="(fItem,fIndex) in formItems">
            <el-col v-bind="colLayout">
              <el-form-item :label="fItem.label" :style="itemStyle" :key="fIndex">
                <template v-if="fItem.type === 'input'">
                  <el-input
                    v-model="formData[`${fItem.field}`]"
                    v-bind="fItem.otherOptions"
                    :placeholder="fItem.placeholder"
                  />
                </template>
                <template v-else-if="fItem.type === 'select'">
                  <el-select
                    style="width: 100%"
                    v-model="formData[`${fItem.field}`]"
                    v-bind="fItem.otherOptions"
                    :placeholder="fItem.placeholder"
                  >
                    <el-option
                      v-for="oItem in fItem.options"
                      :label="oItem.title"
                      :value="oItem.value"
                      :key="oItem.value"
                    />
                  </el-select>
                </template>
                <template v-else-if="fItem.type === 'datepicker'">
                  <el-date-picker
                    v-model="formData[`${fItem.field}`]"
                    v-bind="fItem.otherOptions"
                    :placeholder="fItem.placeholder"
                  />
                </template>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </el-form>
    </div>
    <div class="footer">
      {{ rules }}
      <!-- <slot name="footer"></slot> -->
      <div class="handle-btns">
        <el-button @click="handleResetClick">重置</el-button>
        <el-button type="warning" @click="handleSearchClick">搜索</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SearchForm",
  props: {
    formItems: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Object,
      default: () => {},
    },
    labelWidth: {
      type: String,
      default: "100px",
    },
    colLayout: {
      type: Object,
      default: () => ({
        xl: 6,
        lg: 8,
        md: 12,
        sm: 24,
        xs: 24,
      }),
    },
    itemStyle: {
      type: Object,
      default: () => {
        padding: "10px 40px";
      },
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  created() {},
  methods: {
    // 重置
    handleResetClick() {
      this.formItems.forEach((fItem) => {
        this.formData[fItem.field] = "";
      });
      this.$emit("handleResetClick");
    },
    //搜索
    handleSearchClick() {
      this.$emit("handleSearchClick", this.formData);
    },
  },
};
</script>
<style lang="scss" scoped>
.base-from {
  display: flex;
  flex-direction: column;
}
.footer {
  text-align: end;
}
</style>
