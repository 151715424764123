//mixins.js

export default {
  data() {
    return {};
  },
  methods: {
    handleResetClick() {
      console.log("重置");
    },
    handleSearchClick(searchFormData) {
      console.log("搜索", searchFormData, this.$refs["searchRuleForm"]);
    },
    getTableData(searchInfo) {
      console.log("初始化数据/改变页码2", searchInfo);
      // this.$api.getList(this.searchParams).then((res) => {
      //   console.log("数据", res.data.data);
      // });
    },
    handleDeteleClick(id) {
      // emit('handleDeteleClick')
      console.log("杰哥威武删除", id);
    },
  },
};
