<template>
  <div class="main-box">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Main",
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  // width: 100vh;
}
</style>
